import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";
import jigyonaiyoImg from"../images/jigyo-naiyo.svg"

const JigyoPage = () => (
  <>
  <Layout>
    <SEO title="Jigyo" />

    <Row>
      <Col style={{backgroundColor: 'cornflowerblue', color: 'white', padding:'0.5rem', maxWidth: '960px', margin: '2rem auto 0'}}>内容</Col>
    </Row>

    <Row>
      <Col style={{maxWidth: '960px', margin: '0 auto'}}>
        <Row>
          <Col xs={12} md={6}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyonaiyoImg} />
              <Card.Body>
                <Card.Title>内容1</Card.Title>
                <Card.Text>
                ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyonaiyoImg} />
              <Card.Body>
                <Card.Title>内容2</Card.Title>
                <Card.Text>
                ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyonaiyoImg} />
              <Card.Body>
                <Card.Title>内容3</Card.Title>
                <Card.Text>
                ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col style={{maxWidth: '960px', paddingTop: '3rem'}}></Col>
    </Row>

  </Layout>
  </>
);

export default JigyoPage;
